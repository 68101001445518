import React, { Component } from 'react';
import User from './User'
import { Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import './css/Route.css';
import moment from 'moment';

class Route extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStopId: null,
            selectedTmSequence: null,
            routeinfo: [],
            timetables: [],
            message: "乗りたいバス停を選んでください",
            memo: "なし",
            loading: true,
            timetableLoading: false
        };
    }

    componentDidMount() {
        this.populateRouteData();
        this.populateTimetableData();
    }

    componentDidUpdate(prevprops) {
        if (prevprops.rsvdate !== this.props.rsvdate) {
            this.setState({ selectedStopId: null, selectedTmSequence: null, message: "乗りたいバス停を選んでください" });
            this.populateRouteData();
            this.populateTimetableData();
        }
    }

    render() {
        const { routeId, rsvdate } = this.props;
        const { loading, routeinfo, timetables, selectedStopId, selectedTmSequence, message, timetableLoading, memo } = this.state;

        return (
            <Card className="block">
                <CardBody>
                    {loading && <div>読み込み中...</div>}
                    {!loading && (
                        <>
                            <div className="message">{Route.getDateFormat(this.props.rsvdate)}のバス予約： {message}</div>
                            <div className="button-groups-container">
                                <div className="button-group-container">
                                    <ButtonGroup vertical>
                                        {this.state.routeinfo.map(stop => (
                                            <Button
                                                key={stop.sequence}
                                                color={this.state.selectedStopId === stop.stopId ? 'primary' : 'secondary'}
                                                onClick={() => this.handleStopSelect(stop.stopId, stop.sequence)}>
                                                {stop.stopName} {stop.memo}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                                {selectedStopId && (
                                    <>
                                        <div className="button-group-container">
                                            <ButtonGroup vertical>
                                                {Array.isArray(this.state.timetables) && this.state.timetables.map(stop => (
                                                    <Button
                                                        key={stop.sequence}
                                                        color={this.state.selectedTmSequence === stop.sequence ? 'primary' : 'secondary'}
                                                        onClick={() => this.handleTimeSelect(stop.sequence)}>
                                                        {stop.sequence}便: {moment(stop.departureTime, "HH:mm:ss").format("HH:mm")} {stop.memo}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </div>
                                    </>
                                )}
                                {selectedTmSequence && (
                                    <div className="button-group-container">
                                        <textarea
                                            value={memo} 
                                            onChange={this.handleMemoChange}  
                                        />
                                        <Button
                                            color='primary'
                                            onClick={() => this.handleReserve(selectedStopId, selectedTmSequence, this.props.isToSchool, this.state.memo)}>
                                            予約
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
        );
    }

    static getDateFormat(date) {
        const year = date.getFullYear();  // 2023
        const month = date.getMonth() + 1; // 10 (月は0から始まるので+1を行う)
        const day = date.getDate();      // 19
        return year + "年" + month + "月" + day + "日";
    }

    async populateRouteData() {
        try {
            var userNumber = User.getNumber();
            const response = await fetch(`busreservation/getroutestops?routeId=${this.props.routeId}&userId=${userNumber}`);
            const routedata = await response.json();
            this.setState({ routeinfo: routedata, loading: false });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ loading: false });
        }
    }

    async populateTimetableData() {
        if (this.state.selectedStopId === null) {
            this.setState({ timetables: [], timetableLoading: false });
            return;
        }
        this.setState({ timetableLoading: true });
        try {
            const rsvdate = this.props.rsvdate instanceof Date ? this.props.rsvdate.toISOString() : this.props.rsvdate;
            const tmresponse = await fetch(`busreservation/getroutetimetables?routeId=${this.props.routeId}&stopId=${this.state.selectedStopId}&date=${rsvdate}`);
            const timetabledata = await tmresponse.json();
            this.setState({ timetables: timetabledata, timetableLoading: false });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ timetableLoading: false });
        }
    }

    async sendBusReservation(stopId, sequence, isEnroute, memo) {
        try {
            if (memo == '') {
                memo = 'なし';
            }
            const rsvdate = this.props.rsvdate instanceof Date ? this.props.rsvdate.toISOString() : this.props.rsvdate;
            var userNumber = User.getNumber();
            let formData = new FormData();
            formData.append("personNum", userNumber);
            formData.append("stopId", stopId);
            formData.append("rsvdate", rsvdate);
            formData.append("sequence", sequence);
            formData.append("isenroute", isEnroute);
            formData.append("memo", memo);

            await fetch("busreservation/reservebusride", {
                method: "POST",
                body: formData
            }).then(async response => await response.json())
                .then(data => {
                    console.log(data);
                    this.setState({ message: '予約が完了しました' });
                    // do a page refresh
                    this.props.childToParent();
                });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ loading: false, message: '予約に失敗しました' });
        }
    }

    handleStopSelect = (stopId, sequence) => {
        if (sequence == 1) {
            this.setState({ selectedStopId: stopId, selectedTmSequence: null, timetables: [], message: "始発の予約はできません" });
            return;
        }
        this.setState({ selectedStopId: stopId, selectedTmSequence: null, message: "乗りたいバスの便を選んでください" }, () => {
            this.populateTimetableData();
        });
    }

    handleTimeSelect = (sequence) => {
        this.setState({ selectedTmSequence: sequence, message: "その他ご要望はありますか？" }, () => {
            this.populateTimetableData();
        });
    }

    handleMemoChange = (event) => {
        this.setState({ memo: event.target.value });
    }

    handleReserve = (stopId, sequence, isEnroute, memo) => {
        this.sendBusReservation(stopId, sequence, isEnroute, memo);
    }
}

export default Route;

