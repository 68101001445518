export const DSName = '美池ドライビングスクール'
export const StudentDisplay = '教習生'
export const ReserveScreenMessage = '予約開放は午前１０時からです'

// 予約ページ
export const FewSlotsThreshold = 1
export const RegularSlotsThreshold = 1
export const ManySlotsThreshold = 999

// マイページ
export const DisplayFinancialRecord = 0

// 指導員ページ
export const DisplayInstructorPictures = 0
export const CanChangeInstructorPreference = 0
export const DisplayYusenInstructors = 0

// バス予約ページ
export const DisplayBusReservation = 0

// メンテナンスﾌﾗｸﾞ
export const UnderMaintenance = 0

